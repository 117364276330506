import { ExternalUserInfoProvider } from '@/services/api/my-profile/helpers/type/type';
import { getState } from '@/store/store-helper';

export function navigate(url: string): void {
  window.location.href = url;
}

export function navigateToExternalAuth(backUrl: string, provider: ExternalUserInfoProvider): void {
  const url = encodeURIComponent(backUrl);
  navigate(`https://${import.meta.env.VUE_APP_AUTH_HOST}/auth/begin/${provider}?return_url=${url}`);
}

export function getDocumentUrl(): string {
  return window.location.href;
}

export function replaceUrl(newUrl: string): void {
  window.history.replaceState(null, '', newUrl);
}

type ParamT = 'ntf3' | 'trf';

export function isParamT(url: string, paramT: ParamT): boolean {
  return getUrlSearchParam(url, 't') === paramT;
}

export function isParamNtf(): boolean {
  return getUrlSearchParam(getState().common.index.landingUrl, 't') !== 'trf';
}

export function getUrlSearchParam(url: string, key: string): ParamT | null {
  try {
    const { searchParams } = new URL(url);
    return searchParams.get(key) as ParamT;
  } catch {
    return null;
  }
}
