import { GetStateResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';
import { GetFreeCreditsFeaturesStatusResponse } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/payment_api_pb';

import { getAccessToken } from '@/helpers/auth/auth';
import { LangCode } from '@/services/i18n';
import { CommonStateCreditsFeatures, CommonStateIndex } from '@/store/modules/common/common-state';
import { dispatch } from '@/store/store-helper';

export async function getAndSaveIndexState(): Promise<void> {
  if (getAccessToken()) {
    await dispatch('actionGetState');
  }
}

export function getStateFrom(state: GetStateResponse): CommonStateIndex {
  let likesLimitRemainCount = 0;

  let isPremiumActive = false;
  state.userFeatures.forEach((feature) => {
    if (feature.feature.case === 'allowAdvancedProfile') {
      isPremiumActive = true;
    }

    // Likes feature has params, so we need to know how many likes are left.
    if (feature.feature.case === 'restrictLikesLimit') {
      likesLimitRemainCount = feature.feature.value.remainCount ?? 0;
    }
  });

  return {
    newLikesCount: state.newLikesCount,
    newChatMessagesCount: state.newChatMessagesCount,
    isEmailVerified: state.isEmailVerified,
    interfaceLanguage: state.interfaceLanguage as LangCode,
    hasNewActivity: state.hasNewActivity,
    isPremiumActive,
    likesLimitRemainCount,
    lastVisitedRoute: state.lastVisitedRoute,
    isFromLanding: !state.isOrganic,
    status: state.status,
    isPremiumGoldActive: state.isPremiumGoldActive,
    landingUrl: state.landingUrl,
    lifecycle: {
      isOnboardingComplete: state.lifecycle?.isOnboardingComplete ?? false,
      onboardingPhotosUploadedCount: state.lifecycle?.onboardingPhotosUploadedCount ?? 0,
    },
    isOrganic: state.isOrganic,
  };
}

export function getFreeCreditsFeaturesStatusFrom(
  response: GetFreeCreditsFeaturesStatusResponse,
): CommonStateCreditsFeatures {
  return Object.fromEntries(response.features.map((item) => [item.featureType, item.freeCount]));
}
