import { I18n } from '@/services/i18n';

type Message = {
  cancel: string;
  renew: string;
  write: string;
  visit: string;
  policy: string;
  details: string;
};

const messages: I18n<Message> = {
  en: {
    cancel:
      'You can cancel your subscription anytime you want in account settings. By tapping Submit, we will charge your card',
    renew: 'Your subscription will automatically renew for the same package length you selected at',
    write: 'To cancel subscription, write to',
    visit: 'Please visit our ',
    policy: 'Membership and Refund Policy',
    details:
      'for more details. Also, you can manage your subscription in your account/profile settings',
  },
  es: {
    cancel:
      'Puedes cancelar tu suscripción en cualquier momento en la configuración de la cuenta. Al tocar Enviar, cargaremos tu tarjeta',
    renew:
      'Tu suscripción se renovará automáticamente por el mismo período de tiempo que seleccionaste en',
    write: 'Para cancelar la suscripción, escribe a',
    visit: 'Visita nuestro ',
    policy: 'Política de Membresía y Reembolso',
    details:
      'para más detalles. Además, puedes gestionar tu suscripción en la configuración de tu cuenta',
  },
};

export { messages };
