import { TrialProsperiLookingFor } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  tryNow: string;
  lookingFor: {
    [key in TrialProsperiLookingFor]: string;
  };
  expiresIn: string;
  welcome: string;
  personalOffer: string;
  li1: string;
  li2: string;
  li3: string;
  try7days: string;
  trial1: string;
  trial2: string;
  trial3: string;
  like1: string;
  like2: string;
  like3: string;
  like4: string;
  like5: string;

  message1: string;
  message2: string;
  message3: string;
  message4: string;
  message5: string;

  lookingForSomething: string;
  yourAge: string;
  only: string;
};

const messages: I18n<Message> = {
  en: {
    tryNow: 'TRY NOW',
    lookingFor: {
      serious: 'Serious',
      romantic: 'Romantic',
      flirty: 'Flirty',
      naughty: 'Naughty',
      hookup: 'Hookup',
      relationship: 'Relationship',
      friends: 'Friends',
      skip: 'Casual',
    },
    expiresIn: 'Discount expires in',
    welcome: 'Welcome',
    personalOffer: 'We have a personal offer <br />for you!',
    li1: 'Appear in top of the search: be the first in line and beat the competition',
    li2: "Don't wait for a match! Message her right away using Power Chat",
    li3: 'Join the 83% who went on a date within the first 10 days with this offer',
    try7days: 'Try MeetMyAge for 7 days, <br />and you will:',
    trial1: 'Unlock all her photos and access premium search filters',
    trial2: 'Capture the top spot: rise above other men and be the first in search results',
    trial3: 'Get unlimited messages - engage with her and keep the spark alive',
    like1: "You've run out of likes",
    like2: 'Try MeetMyAge for 7 days, and you will:',
    like3: 'Get unlimited likes: supercharge your chances to find a match',
    like4: 'Unlock all her photos and access premium search filters',
    like5: 'Capture the top spot: rise above other men and be the first in search results',

    message1: "You've run out of messages",
    message2: 'Try MeetMyAge for 7 days, and you will:',
    message3: 'Get unlimited messages - engage with her and keep the spark alive',
    message4: "Uncover when she's thinking of you — get notified when she views your profile",
    message5:
      'Take control: 96% agree that the delete own messages function saved their connection',

    lookingForSomething: 'Looking for something',
    yourAge: 'Your age',
    only: 'only',
  },
  es: {
    tryNow: 'PRUEBA AHORA',
    lookingFor: {
      serious: 'Serio',
      romantic: 'Romántico',
      flirty: 'Coqueto',
      naughty: 'Travieso',
      hookup: 'Encuentro',
      relationship: 'Relación',
      friends: 'Amigos',
      skip: 'Casual',
    },

    expiresIn: 'El descuento expira en',
    welcome: 'Bienvenido',
    personalOffer: '¡Tenemos una oferta personal <br />para ti!',

    li1: 'Aparece en la parte superior de la búsqueda: ¡sé el primero en la fila y vence a la competencia!',
    li2: '¡No esperes a un match! Mensajéala de inmediato usando Power Chat',
    li3: 'Únete al 83% que tuvo una cita en los primeros 10 días con esta oferta',

    try7days: 'Prueba MeetMyAge por 7 días, <br />y obtendrás:',

    trial1: 'Desbloquea todas sus fotos y accede a los filtros de búsqueda premium',
    trial2:
      'Captura el primer lugar: ¡supérate a otros hombres y sé el primero en los resultados de búsqueda!',
    trial3: 'Obtén mensajes ilimitados: ¡interactúa con ella y mantén la chispa viva!',

    like1: 'Te has quedado sin likes',
    like2: 'Prueba MeetMyAge por 7 días, y obtendrás:',
    like3: 'Obtén likes ilimitados: ¡aumenta tus posibilidades de encontrar un match!',
    like4: 'Desbloquea todas sus fotos y accede a los filtros de búsqueda premium',
    like5:
      'Captura el primer lugar: ¡supérate a otros hombres y sé el primero en los resultados de búsqueda!',

    message1: 'Te has quedado sin mensajes',
    message2: 'Prueba MeetMyAge por 7 días, y obtendrás:',
    message3: 'Obtén mensajes ilimitados: ¡interactúa con ella y mantén la chispa viva!',
    message4: 'Descubre cuándo está pensando en ti: ¡recibe una notificación cuando vea tu perfil!',
    message5:
      'Toma el control: el 96% está de acuerdo en que la función de eliminar mensajes propios salvó su conexión',

    lookingForSomething: 'Buscando algo',
    yourAge: 'Tu edad',
    only: 'solo',
  },
};

export { messages };
