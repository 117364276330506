import { I18n } from '@/services/i18n';

type Message = {
  thanks: string;
  great: string;
  enjoy: string;
  tap: string;
  notNow: string;
  cancel: string;
  submit: string;
};

const messages: I18n<Message> = {
  en: {
    thanks: 'Thanks for submitting a review!',
    great: 'Great',
    enjoy: 'Enjoying MeetMyAge?',
    tap: 'Tap a star to rate',
    notNow: 'Not Now',
    cancel: 'Cancel',
    submit: 'Submit',
  },
  es: {
    thanks: '¡Gracias por enviar una reseña!',
    great: 'Genial',
    enjoy: '¿Disfrutando de MeetMyAge?',
    tap: 'Toca una estrella para calificar',
    notNow: 'No ahora',
    cancel: 'Cancelar',
    submit: 'Enviar',
  },
};

export { messages };
