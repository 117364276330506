import { I18n } from '@/services/i18n';

type Message = {
  charged: string;
  cancel: string;
  agree: string;
  period: string;
  every: string;
  write: string;
  visit: string;
  policy: string;
  details: string;
};

const messages: I18n<Message> = {
  en: {
    charged: 'You will be charged',
    cancel: 'You can cancel your trial anytime you want in account settings. By tapping',
    agree: ', you agree that if you don’t cancel at least 24 hours before the end of the',
    period: 'introductory period, you will automatically be charged the total price of',
    every: 'every',
    write: 'To cancel subscription, write to',
    visit: 'Please visit our ',
    policy: 'Membership and Refund Policy',
    details:
      'for more details. Also, you can manage your subscription in your account/profile settings',
  },
  es: {
    charged: 'Se le cobrará',
    cancel:
      'Puedes cancelar tu prueba en cualquier momento en la configuración de la cuenta. Al tocar',
    agree:
      'aceptas que si no cancelas al menos 24 horas antes del final del período introductorio de',
    period: ', se te cobrará automáticamente el precio total de',
    every: 'cada',
    write: 'Para cancelar la suscripción, escriba a',
    visit: 'Por favor, visita nuestro ',
    policy: 'Política de Membresía y Reembolso',
    details:
      'para más detalles. Además, puedes gestionar tu suscripción en la configuración de tu cuenta/perfil',
  },
};

export { messages };
