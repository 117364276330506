import { TrialProsperiLookingFor } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  tryNow: string;
  lookingFor: {
    [key in TrialProsperiLookingFor]: string;
  };
  getPremium50: string;
  expiresIn: string;

  like1: string;
  like2: string;
  like3: string;
  like4: string;
  like5: string;

  message1: string;
  message2: string;
  message3: string;
  message4: string;
  message5: string;

  premium1: string;
  premium2: string;
  premium3: string;
  premium4: string;

  lookingForSomething: string;
  yourAge: string;
  only: string;
};

const messages: I18n<Message> = {
  en: {
    tryNow: 'GET PREMIUM',
    lookingFor: {
      serious: 'Serious',
      romantic: 'Romantic',
      flirty: 'Flirty',
      naughty: 'Naughty',
      hookup: 'Hookup',
      relationship: 'Relationship',
      friends: 'Friends',
      skip: 'Casual',
    },
    getPremium50: 'GET -50% PREMIUM',
    expiresIn: 'Discount expires in',

    like1: "You've run out of likes",
    like2: 'Get Premium with 50% off and you will:',
    like3: 'Get unlimited likes: supercharge your chances to find a match',
    like4: 'Unlock all her photos and access premium search filters',
    like5: 'Capture the top spot: rise above other men and be the first in search results',

    message1: "You've run out of messages",
    message2: 'Get Premium with 50% off and you will:',
    message3: 'Get unlimited messages - engage with her and keep the spark alive',
    message4: "Uncover when she's thinking of you — get notified when she views your profile",
    message5:
      'Take control: 96% agree that the delete own messages function saved their connection',

    premium1: 'Get Premium with 50% off <br />and&nbsp;you will:',
    premium2: 'Unlock all her photos and access premium search filters',
    premium3: 'Capture the top spot: rise above other men and be the first in search results',
    premium4: 'Get unlimited messages - engage with her and keep the spark alive',

    lookingForSomething: 'Looking for something',
    yourAge: 'Your age',
    only: 'only',
  },
  es: {
    tryNow: 'OBTÉN PREMIUM',
    lookingFor: {
      serious: 'Serio',
      romantic: 'Romántico',
      flirty: 'Coqueto',
      naughty: 'Travieso',
      hookup: 'Encuentro',
      relationship: 'Relación',
      friends: 'Amigos',
      skip: 'Casual',
    },
    getPremium50: 'OBTÉN PREMIUM -50%',
    expiresIn: 'Descuento expira en',

    like1: 'Te has quedado sin likes',
    like2: 'Obtén Premium con un 50% de descuento y:',
    like3: 'Consigue likes ilimitados: aumenta tus posibilidades de encontrar pareja',
    like4: 'Desbloquea todas sus fotos y accede a filtros de búsqueda premium',
    like5: 'Destaca sobre los demás: sé el primero en los resultados de búsqueda',

    message1: 'Te has quedado sin mensajes',
    message2: 'Obtén Premium con un 50% de descuento y:',
    message3: 'Consigue mensajes ilimitados: mantén la chispa viva',
    message4: 'Descubre cuándo piensa en ti: recibe una notificación cuando vea tu perfil',
    message5:
      'Toma el control: el 96% afirma que la función de eliminar mensajes salvó su conexión',

    premium1: 'Obtén Premium con un 50% de descuento y:',
    premium2: 'Desbloquea todas sus fotos y accede a filtros de búsqueda premium',
    premium3: 'Destaca sobre los demás: sé el primero en los resultados de búsqueda',
    premium4: 'Consigue mensajes ilimitados: mantén la chispa viva',

    lookingForSomething: 'Buscando algo',
    yourAge: 'Tu edad',
    only: 'solo',
  },
};

export { messages };
